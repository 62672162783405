<template>
  <v-app>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="orders"
        :page.sync="page"
        :server-items-length="meta.total"
        :items-per-page="meta.per_page"
        :loading="loading"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <state-indicate ref="indicate" v-slot:default="{isLoading}">
            <v-toolbar
              flat
            >
              <v-toolbar-title>Orders List</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="showProductsModal"
              >
                <v-card>
                  <v-card-title>
                    <span class="headline">Products</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-data-table
                          :headers="productsHeaders"
                          :items="products"
                          :page.sync="page"
                          :server-items-length="meta.total"
                          :items-per-page="meta.per_page"
                          :loading="loading"
                          hide-default-footer
                          class="elevation-1"
                        >
                        </v-data-table>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="showProductsModal = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn-->
<!--                    color="primary"-->
<!--                    dark-->
<!--                    class="mb-2"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                  >-->
<!--                    New Item-->
<!--                  </v-btn>-->
<!--                </template>-->
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>

                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <ValidationProvider name="User" rules="required|integer">
                              <v-autocomplete
                                slot-scope="{
                                  errors,
                                  valid
                                }"
                                :items="users"
                                item-text="email"
                                item-value="id"
                                v-model="editedItem.user_id"
                                :error-messages="errors"
                                :success="valid"
                                label="User"
                              ></v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <ValidationProvider name="Total amount" :rules="{required: true, regex: /^\d*\.?\d*$/}">
                              <v-text-field
                                slot-scope="{
                                  errors,
                                  valid
                                }"
                                v-model="editedItem.total_amount"
                                :error-messages="errors"
                                :success="valid"
                                label="Total amount"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <ValidationProvider name="Status">
                              <v-text-field
                                slot-scope="{
                                  errors,
                                  valid
                                }"
                                v-model="editedItem.status"
                                :error-messages="errors"
                                :success="valid"
                                label="Status"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                        :disabled="isLoading"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                        :disabled="invalid"
                        :loading="isLoading"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </ValidationObserver>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" :disabled="isLoading" text @click="closeDelete">Cancel</v-btn>

                    <v-btn color="blue darken-1" text :loading="isLoading" @click="deleteItemConfirm">OK</v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </state-indicate>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="showProducts(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </v-container>
  </v-app>
</template>

<script>
    import OrdersApi from '@/api/orders';
    import UsersApi from '@/api/users';
    import {ValidationProvider, ValidationObserver, withValidation} from 'vee-validate';
    import StateIndicate from "../../../components/utils/StateIndicate";
    import Order from "../../../models/Order";

    export default {
        name: "Index",

        data() {
            return {
                loading: false,
                dialog: false,
                dialogDelete: false,
                editedIndex: -1,
                orders: [],
                users: [],
                editedItem: {
                    id: '',
                    user_id: '',
                    total_amount: null,
                    status: ''
                },
                defaultItem: {
                    id: '',
                    user_id: '',
                    total_amount: null,
                    status: ''
                },
                headers: [
                    {text: 'User id', value: 'user_id'},
                    {text: 'Total amount', value: 'total_amount'},
                    {text: 'Status', value: 'status'},
                    {text: 'Created at', value: 'created_at'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                meta: {},
                page: 1,

                productsHeaders: [
                    {text: 'Name', value: 'name'},
                    {text: 'Author', value: 'author'},
                    {text: 'Description', value: 'description'},
                    {text: 'Price', value: 'price'},
                    {text: 'Quantity', value: 'quantity'},
                    {text: 'Created at', value: 'created_at'},
                ],
                showProductsModal: false,
                products: []
            }
        },

        watch: {
            async page() {
                this.fetchOrders()
            },
            formTitle() {
                return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
            },
        },

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
            },
        },

        async mounted() {
            this.fetchOrders()
            this.fetchUsers()
        },

        methods: {
            fetchUsers() {
                this.loading = true
                return UsersApi.list().then(ApiResponse => {
                    this.users = ApiResponse.getData();
                    this.loading = false
                });
            },
            fetchOrders() {
                this.loading = true
                return Order.page(this.page).get().then(ApiResponse => {
                    this.orders = ApiResponse.getData();
                    this.meta = ApiResponse.getMeta();
                    this.loading = false
                });
            },
            showProducts(item) {
                this.showProductsModal = true
                let products = item.products
                for (let i in products) {
                    products[i].id = i
                }
                this.products = products;
            },
            editItem(item) {
                this.editedIndex = this.orders.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.orders.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            async deleteItemConfirm() {
                this.$refs.indicate.isLoading = true
                try {
                    await OrdersApi.delete(this.editedItem.id)
                    this.closeDelete()
                    this.fetchOrders()
                } catch (e) {}
                this.$refs.indicate.isLoading = false
            },

            close() {
                this.$refs.observer.reset()
                this.dialog = false
                this.$nextTick(() => {
                    Object.assign(this.editedItem, this.defaultItem)
                    console.log(this.editedItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    Object.assign(this.editedItem, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            async save() {
                if (await this.$refs.observer.validate()) {
                    let formData = await this.getFormData()
                    this.$refs.indicate.isLoading = true;
                    if (this.editedIndex > -1) {
                        try {
                            await OrdersApi.update(this.editedItem.id, formData);
                            Object.assign(this.orders[this.editedIndex], this.editedItem);
                            this.close();
                        } catch (e) {
                            alert('Error while for updating book')
                        }
                    } else {
                        try {
                            await OrdersApi.store(formData);
                            this.close()
                            this.fetchOrders()
                        } catch (e) {
                            alert('Error while for creating book')
                        }
                    }
                    this.$refs.indicate.isLoading = false;
                }
            },

            async getFormData() {
                let formData = new FormData();

                Object.keys(this.editedItem).forEach(key => {
                    formData.append(key, this.editedItem[key])
                });

                if (this.editedIndex > -1) {
                    formData.append('_method', 'PUT')
                }

                return formData;
            }
        },

        components: {
            ValidationProvider, ValidationObserver, StateIndicate
        }
    }
</script>

<style scoped>

</style>
