var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"page":_vm.page,"server-items-length":_vm.meta.total,"items-per-page":_vm.meta.per_page,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('state-indicate',{ref:"indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Orders List")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{model:{value:(_vm.showProductsModal),callback:function ($$v) {_vm.showProductsModal=$$v},expression:"showProductsModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Products")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.productsHeaders,"items":_vm.products,"page":_vm.page,"server-items-length":_vm.meta.total,"items-per-page":_vm.meta.per_page,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showProductsModal = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"User","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
                                var valid = ref.valid;
return _c('v-autocomplete',{attrs:{"items":_vm.users,"item-text":"email","item-value":"id","error-messages":errors,"success":valid,"label":"User"},model:{value:(_vm.editedItem.user_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "user_id", $$v)},expression:"editedItem.user_id"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Total amount","rules":{required: true, regex: /^\d*\.?\d*$/}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
                                var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Total amount"},model:{value:(_vm.editedItem.total_amount),callback:function ($$v) {_vm.$set(_vm.editedItem, "total_amount", $$v)},expression:"editedItem.total_amount"}})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
                                var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Status"},model:{value:(_vm.editedItem.status),callback:function ($$v) {_vm.$set(_vm.editedItem, "status", $$v)},expression:"editedItem.status"}})}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":isLoading},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid,"loading":isLoading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":isLoading,"text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":isLoading},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]}}])})]},proxy:true},{key:"item.actions",fn:function(ref){
                                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showProducts(item)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.meta.last_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }